import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import Image from '../components/uielements/Image'
import MainTitle from '../components/uielements/MainTitle'
import Spacer from '../components/uielements/Spacer'
import Button from '../components/uielements/Button'
import { useTranslation } from 'react-i18next'
import BlogSlider from '../components/buildingBlocks/BlogSlider'
import Columns from '../components/uielements/Columns'
import Wrapper from '../components/Wrapper'
import Seo from '../components/seo'
import { Helmet } from 'react-helmet'
import RichContent from '../components/uielements/RichContent/richContent'

const TeamBoxTitle = styled.div`
    padding: 40px;
    text-align: center;
    background: ${({ theme }) => theme.blue};
    border-bottom: 3px solid ${({ theme }) => theme.purple};
    color: #fff;
    box-shadow: 0 0 10px #ccc;
    border-radius: 6px;
    font-size: 1.4em;
    font-weight: bold;
`

const TeamPost = ({ data: { team } }) => {
    const { t } = useTranslation()
    return (
        <Layout>
            <Helmet>
                {team.featuredImage && (
                    <meta
                        property="og:image"
                        content={team.featuredImage.url}
                    />
                )}
                <meta property="og:title" content={team.title} />
            </Helmet>
            <Wrapper>
                <Seo title={team.title + ' - ' + team.position} />
                <Spacer top="3" />
                <TeamBoxTitle>{t('team')}</TeamBoxTitle>
                <Spacer top="4" />
                <MainTitle>{team.title}</MainTitle>
                <Spacer top="2" />
                <h3>
                    <strong>{team.position}</strong>
                </h3>
                <Spacer top="4" />
                <Columns>
                    <Image obj={team.featuredImage} />
                    <RichContent body={team?.body?.raw} />
                    <Spacer top="10" />
                    <Button link="/team">{t('team')}</Button>
                </Columns>
                <BlogSlider />
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
    query team($slug: String) {
        team: contentfulTeam(slug: { eq: $slug }) {
            id
            slug
            title
            position
            body {
                raw
            }
            featuredImage {
                url
                title
                gatsbyImageData(
                    quality: 75
                    jpegProgressive: true
                    sizes: "1920, 960, 480"
                    cropFocus: CENTER
                )
            }
        }
    }
`

export default TeamPost
